/**
 * @generated SignedSource<<a38a1af1b1288c25a088a58f631e7187>>
 * @relayHash 9339a151bca8370543296e5653c773b9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/505.0.0-2025-04-02T17:54:06.053Z/internalQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type internalQuery$variables = {
  customBannerText?: string | null;
  dotComHost?: string | null;
  hasSellerPk?: boolean | null;
  hyperwalletLink?: string | null;
  isDealer?: boolean | null;
  renderNotifications?: boolean | null;
  sellerPk?: string | null;
  userId: string;
};
export type internalQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"NavHeader_viewer">;
  };
};
export type internalQuery = {
  response: internalQuery$data;
  variables: internalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "customBannerText"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "dotComHost"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "hasSellerPk"
},
v3 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "hyperwalletLink"
},
v4 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isDealer"
},
v5 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "renderNotifications"
},
v6 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "sellerPk"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v10 = [
  {
    "kind": "Variable",
    "name": "customBannerText",
    "variableName": "customBannerText"
  },
  {
    "kind": "Variable",
    "name": "dotComHost",
    "variableName": "dotComHost"
  },
  {
    "kind": "Variable",
    "name": "hyperwalletLink",
    "variableName": "hyperwalletLink"
  },
  {
    "kind": "Variable",
    "name": "sellerPk",
    "variableName": "sellerPk"
  },
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "href",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interactionType",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hideSideNavTitle",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataTn",
  "storageKey": null
},
v19 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isNavBarElement"
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newWindow",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exactLocation",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "badgeText",
  "storageKey": null
},
v24 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NavBarListItem",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        (v13/*: any*/),
        (v14/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v12/*: any*/),
            (v18/*: any*/),
            (v16/*: any*/),
            (v20/*: any*/),
            (v21/*: any*/),
            (v23/*: any*/)
          ],
          "type": "NavBarElement",
          "abstractKey": "__isNavBarElement"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavBarList",
  "abstractKey": null
},
v25 = [
  (v15/*: any*/),
  (v19/*: any*/),
  (v12/*: any*/),
  (v18/*: any*/),
  (v16/*: any*/),
  (v20/*: any*/),
  (v21/*: any*/),
  (v22/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v24/*: any*/)
],
v26 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "desktopNav",
  "plural": true,
  "selections": (v25/*: any*/),
  "storageKey": null
},
v27 = [
  {
    "kind": "Variable",
    "name": "sellerId",
    "variableName": "sellerPk"
  }
],
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "PresenceResults",
  "kind": "LinkedField",
  "name": "presence",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recentOnlineDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "SellerPreferences",
  "kind": "LinkedField",
  "name": "sellerPreferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showOnlineStatus",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "internalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NavHeader_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "internalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "presenceFeatureFlag",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "presence"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"presence\")"
          },
          {
            "alias": "presenceRolloutPercentage",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "presenceRolloutPercentage"
              },
              {
                "kind": "Literal",
                "name": "type",
                "value": "graphQLConfig"
              }
            ],
            "kind": "ScalarField",
            "name": "config",
            "storageKey": "config(key:\"presenceRolloutPercentage\",type:\"graphQLConfig\")"
          },
          (v8/*: any*/),
          {
            "condition": "isDealer",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "DealerNavBar",
                "kind": "LinkedField",
                "name": "dealerNavBar",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NavBarButton",
                    "kind": "LinkedField",
                    "name": "buttons",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NavBarLinks",
                    "kind": "LinkedField",
                    "name": "links",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "desktopSideNav",
                        "plural": true,
                        "selections": [
                          (v15/*: any*/),
                          (v12/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v13/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v14/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "NavBarListItem",
                                "kind": "LinkedField",
                                "name": "items",
                                "plural": true,
                                "selections": [
                                  (v12/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/),
                                  (v20/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v18/*: any*/)
                                    ],
                                    "type": "NavBarElement",
                                    "abstractKey": "__isNavBarElement"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "NavBarList",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bannerText",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "navBar",
                "args": (v10/*: any*/),
                "concreteType": "DealerNavBar",
                "kind": "LinkedField",
                "name": "dealerNavBar",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NavBarButton",
                    "kind": "LinkedField",
                    "name": "buttons",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v8/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NavBarLinks",
                    "kind": "LinkedField",
                    "name": "links",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "mobileNav",
                        "plural": true,
                        "selections": (v25/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "accountNav",
                        "plural": true,
                        "selections": [
                          (v15/*: any*/),
                          (v19/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v12/*: any*/),
                          (v18/*: any*/),
                          (v16/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v26/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "condition": "renderNotifications",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": "notifications",
                        "args": null,
                        "concreteType": "DealerNavBarBadges",
                        "kind": "LinkedField",
                        "name": "badges",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "listings",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "orders",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "messages",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "recommendations",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "condition": "hasSellerPk",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v27/*: any*/),
                    "concreteType": "Seller",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": [
                      (v28/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RepData",
                        "kind": "LinkedField",
                        "name": "sellerRep",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vertical",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          (v9/*: any*/)
                        ],
                        "concreteType": "CreateListingOption",
                        "kind": "LinkedField",
                        "name": "createListingOptions",
                        "plural": true,
                        "selections": [
                          (v18/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VatConfiguration",
                        "kind": "LinkedField",
                        "name": "vatConfiguration",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "priceBookName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SellerProfile",
                        "kind": "LinkedField",
                        "name": "sellerProfile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "company",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v29/*: any*/),
                      (v30/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "condition": "hasSellerPk",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v27/*: any*/),
                "concreteType": "Seller",
                "kind": "LinkedField",
                "name": "seller",
                "plural": false,
                "selections": [
                  (v30/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isDealer",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "reduceShortGroups",
                    "value": true
                  },
                  (v9/*: any*/)
                ],
                "concreteType": "InternalNavBar",
                "kind": "LinkedField",
                "name": "internalNavBarLinks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NavBarLinks",
                    "kind": "LinkedField",
                    "name": "links",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "mobileNav",
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "desktopNav",
                        "plural": true,
                        "selections": (v25/*: any*/),
                        "storageKey": null
                      },
                      (v26/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/505.0.0-2025-04-02T17:54:06.053Z/internalQuery",
    "metadata": {},
    "name": "internalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "991c7b81d6a43c86ea5532a4001f6ec2";

export default node;
